<template>
	<a-layout>
		<a-layout-header>
			<h3>录入话术</h3>
		</a-layout-header>
		<a-layout-content>
			<a-carousel dotPosition="top" arrows>
				<template #prevArrow>
					<div class="custom-slick-arrow" style="left: 10px; z-index: 1">
						<left-circle-outlined />
					</div>
				</template>
				<template #nextArrow>
					<div class="custom-slick-arrow" style="right: 10px">
						<right-circle-outlined />
					</div>
				</template>
				<div v-for="item in dialogs" :key="item" class="li-dialog">
					<a-card style="height:auto">
						<!-- <p v-html="showSentence(item)"></p> -->
						<a-textarea disabled class="list-words" auto-size :value="item" />
					</a-card>
				</div>
			</a-carousel>
		</a-layout-content>
		<a-layout-content>
			<div>
				<a-textarea placeholder="请输入话术" :auto-size="{ minRows: 2 }" v-model:value="sentence"
					:allowClear="true" />
			</div>
		</a-layout-content>
		<a-layout-footer>
			<a-button block type="primary" @click="dialogs.push(sentence)">添加</a-button>
			<a-button block @click="randCopy">随机复制</a-button>
			<div>
				<a-textarea placeholder="预览内容．．页面切换回来后,自动刷新随机话术,并完成复制" disabled :auto-size="{ minRows: 2 }" v-model:value="previewDialog"
					:allowClear="true" />
			</div>
		</a-layout-footer>
	</a-layout>
</template>

<script>
	import {
		LeftCircleOutlined,
		RightCircleOutlined
	} from '@ant-design/icons-vue';
	import {
		defineComponent
	} from 'vue';

	export default {
		name: 'HelloWorld',
		methods: {
			onChange: (current) => {
				console.log(current);
			},

			isAtContact(text, pos) {
				let chr = text[pos];
				let prev = text[pos - 1],
					next = text[pos + 1];
				if (chr == undefined) {
					return false;
				} else if (prev == undefined | next == undefined) {
					return /[a-zA-Z\d]+/ig.exec(chr)
				} else {
					let b1 = /[a-zA-Z\d]+/ig.exec(prev) != null,
						b2 = /[a-zA-Z\d]+/ig.exec(next) != null;
					return (b1 | b2)
				}
			},

			addToCarousel(val) {
				this.dialogs.push(val)
			},
			showSentence(val) {
				// return val
				return val.replace(/\n+/g, '<br/>')
			},
			genBetweenAll(m, n) {
				return Math.floor(Math.random() * (n - m + 1)) + m;
			},
			rndPosForStrOrArray(o) {
				return this.genBetweenAll(0, o.length - 1);
			},
			getRndStrOf(strOrArray) {
				var n = strOrArray.length;
				var i = this.genBetweenAll(0, n - 1);
				return strOrArray[i];
			},
			rndInsertForStr(str, pos, chr) {
				let strs = Array.from(str);
				// let pos =this.rndPosForStrOrArray(strs);
				if (!this.isAtContact(str, pos))
					strs.splice(pos, 0, chr);
				return strs.join("");
			},
			insertRndSpecialWords(text) {
				let words = this.randomWords;
				let maxTimes = 4;
				let minTimes = 2;
				let times = this.genBetweenAll(minTimes, maxTimes);
				let right = Math.floor(text.length / 2);
				let left = 0;
				for (let i = 0; i < times && i < text.length; i++) {
					let n = this.genBetweenAll(left, right);
					let chr = this.getRndStrOf(words);
					if (chr == undefined) continue;
					text = this.rndInsertForStr(text, n, chr);
					left = Math.floor((n + right) / 2);
					right = text.length - 1;
				}
				return text;
			},
			replaceKeywords(text) {
				for (let k in this.reservedWords) {
					let v = this.reservedWords[k];
					console.log(k)
					let re = new RegExp(k, "ig");
					text = text.replace(re, v instanceof Array ? this.getRndStrOf(v) : v);
				}
				return text;
			},
			solveText(text) {
				text = this.insertRndSpecialWords(text);
				text = this.replaceKeywords(text);
				console.log(text)
				return text;
			},
			randCopy(ee) {
				for (let k in this.randomWords) {
					console.log(this.randomWords[k]);
				}

				let n = this.dialogs.length;
				let i = this.genBetweenAll(0, n - 1);
				let dialog = this.dialogs[i];
				let outStr = dialog;
				while (outStr == dialog) {
					outStr = this.solveText(dialog)
				}

				(function() {
					document.oncopy = function(e) {
						e.clipboardData.setData('text', outStr);
						e.preventDefault();
						document.oncopy = null;
					}
				})(outStr);
				document.execCommand('Copy');
				this.previewDialog = outStr;
			}
		},
		setup() {
			return {
				// dotPosition: ref<CarouselProps['dotPosition']>('top')
			}
		},
		data() {
			return {
				sentence: "",
				previewDialog: "",
				randomWords: "◦▸▹◂◃▾▿▴▵▫︎▪︎◌︎◈⚜︎☄︎✦✭✤✺✵✭✮✬❤☀⋅⋄⊹∘∙∗≺ℏℵℳ⁺ℷ",
				reservedWords: {
					"赚": "贝兼",
					"钱": "💰",
					"兼职": ["兼耳只", "简耳只"],
					"稿": "禾高",
					"月入": "月人",
					"加": ["➕", "佳", "家", "嘉"],
					"复制": ["父制", "复志", "副·制", "制复"],
					"粘贴": ["占贴", "粘帖", "粘铁", "粘贝占", "站铁", "贴粘"],
					"创业": ["创🍀", "创页", "仓刂页"],
					"我": ["我", "沃"],
					"翻身": ["翻生", "帆身"],
					"在家": ["再家", "崽家", "在佳", "在嘉"]
				},
				dialogs: []
			}
		},
		props: {
			msg: String,
			// dialogs: Array
		},
		components: {
			LeftCircleOutlined,
			RightCircleOutlined,
		},
		mounted() {
			let that = this
			window.addEventListener('visibilitychange',  ()=>{
				that.randCopy(false)
			});
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.ant-layout-content {
		margin: 10px 40px;
	}

	button {
		margin: 4px 0;
	}

	h3 {
		font-weight: bold;
		color: #fff;
		text-shadow: slategray 0.0625rem;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #42b983;
	}

	.a-layout-header,
	.a-layout-footer {
		color: #fff;
		background: #7dbcea;
	}

	.ant-layout-header {
		background: #6aa0c7;
	}

	.ant-layout-content {
		/* background: #107bcb; */
		/* min-height: 200px; */
	}

	.a-textarea {
		background-color: rgba(0.3, 0.3, .3, 0.5);
		border: 1px blue solid;
		min-height: 200px;
	}

	.a-carousel {
		/* min-height: 40px; */
	}

	.ant-carousel :deep(.slick-slide) {
		text-align: center;
		background: #364d79;
		overflow: hidden;
	}

	.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
		width: 25px;
		height: 25px;
		font-size: 25px;
		color: #fff;
		background-color: rgba(31, 45, 61, 0.11);
		opacity: 0.3;
		z-index: 1;
	}

	.ant-carousel :deep(.custom-slick-arrow:before) {
		display: none;
	}

	.ant-carousel :deep(.custom-slick-arrow:hover) {
		opacity: 0.5;
	}

	.ant-carousel :deep(.slick-slide h3) {
		color: #fff;
	}

	.ant-card {
		background-color: rgba(0, 0, 0, 0);
		border: none;
		color: blue;
		margin: 20px;
		text-align: left;
	}

	.a-card {
		background-color: rgba(0, 0, 0, 0);
	}

	.list-words {
		background-color: rgba(0, 0, 0, 0);
		color: white;
		min-height: 5rem;
		border: none;
		width: 100%;
	}

	.slick-current {
		height: auto;
	}
</style>